import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ChatComponent.css';

// Correct imports for CodeMirror
import { EditorState } from '@codemirror/state';
import { EditorView, keymap, lineNumbers } from '@codemirror/view'; // Make sure to import EditorView, keymap, and lineNumbers from '@codemirror/view'

import { javascript } from '@codemirror/lang-javascript';
import { html } from '@codemirror/lang-html';
import { css } from '@codemirror/lang-css';
import { python } from '@codemirror/lang-python';
import { php } from '@codemirror/lang-php';
import { oneDark } from '@codemirror/theme-one-dark';
import { defaultKeymap } from '@codemirror/commands';

import DOMPurify from 'dompurify';
import CodeDisplay from './CodeDisplay'; // Ensure the path is correct





// Chat Component
const ChatComponent = ({ assistant: propAssistant, updateTrigger  }) => {
    const { apiAssistantId: paramApiAssistantId, token } = useParams(); // Get the API assistant ID and token from the URL
    const [apiAssistantId, setApiAssistantId] = useState(propAssistant ? propAssistant.api_assistant_id : paramApiAssistantId); // Use the API assistant ID from the URL if it exists
    const [assistant, setAssistant] = useState(propAssistant); // Use the assistant from the props if it exists
    const [threadId, setThreadId] = useState(null); // State to store the thread ID
    const [messages, setMessages] = useState([]); // State to store the messages
    const [inputValue, setInputValue] = useState(''); // State to store the chat input
    const [isTyping, setIsTyping] = useState(false); // State to store whether the assistant is typing
    const chatContainerRef = useRef(null); // Ref for the chat container
    const [color, setColor] = useState(['#9932CC', '#007bff', '#008000'][Math.floor(Math.random() * 3)]); // State to store the color
    const isInIframe = window.location !== window.parent.location; // Check if the app is in an iframe
    const [isAudioLoading, setIsAudioLoading] = useState(false); // State to store whether the audio is loading
    const [mathInput, setMathInput] = useState(''); // State to store MathQuill input
    const mathFieldRef = useRef(null); // Ref for the MathQuill input field
    const [showMathInput, setShowMathInput] = useState(false); // State to store whether to show the MathQuill input
    const mathToggleRef = useRef(null); // Ref for the MathQuill toggle button
    const editorDiv = useRef(null); 
    const [editor, setEditor] = useState(null); 
    const [languageMode, setLanguageMode] = useState(html);
    const [showCodeEditor, setShowCodeEditor] = useState(false);  // State to store whether to show the CodeMirror editor
    const [languageLabel, setLanguageLabel] = useState('html'); // Initialize with 'html'
    const [expandedMessages, setExpandedMessages] = useState(new Set());


 
    const toggleExpand = (index) => {
        setExpandedMessages(prevExpanded => {
          const newExpanded = new Set(prevExpanded);
          if (newExpanded.has(index)) {
            newExpanded.delete(index);
          } else {
            newExpanded.add(index);
          }
          return newExpanded;
        });
      };

      function PopOut({ message, onClose }) {
        const segments = message.content.split(/(```.*?```)/s);
      
        useEffect(() => {
          // Trigger MathJax to process the new content in the pop-out
          if (window.MathJax) {
            window.MathJax.typesetPromise();
          }
        }, [message]);  // Dependency array includes 'message' to re-trigger when message changes

        return (
          <div className="pop-out-container">
            <button className="pop-out-close" onClick={onClose}>
            <i className="fas fa-times"></i>
            </button>
            
            {/* Scrollable content area */}
      <div className="pop-out-scrollable-content">
              {segments.map((segment, index) => {
                if (segment.startsWith("```") && segment.endsWith("```")) {
                  const [language, ...codeLines] = segment.substring(3, segment.length - 3).split('\n');
                  const code = codeLines.join('\n').trim();
                  return <CodeDisplay key={index} code={code} language={language.trim()} />;
                } else {
                  // Apply formatMessageWithLatex to process LaTeX math
                  return (
                    <span key={index} style={{ fontWeight: 'normal' }} 
                      dangerouslySetInnerHTML={{ 
                        __html: formatMessageWithLatex(processMessageContent(segment))
                      }} 
                    />
                  );
                }
              })}
            </div>
            
          </div>
        );
      }
      
      
   




const toggleCodeEditor = () => {
    setShowCodeEditor(!showCodeEditor);
    console.log("Toggled showCodeEditor to", !showCodeEditor); // For debugging
};


    

    // Function to toggle the MathQuill input visibility
    const toggleMathInput = () => {
        setShowMathInput(!showMathInput);
        console.log("Toggled showMathInput to", !showMathInput); // Add this line for debugging
    };
    
    // Function to handle the MathQuill submit button click
    const handleMathSubmit = () => {
        // Append MathQuill input wrapped in backticks to the current chat input
        setInputValue(prevInputValue => `${prevInputValue}${prevInputValue ? ' ' : ''}\`${mathInput}\``);
    
        // Clear MathQuill input state
        setMathInput('');
    
        // Directly reset the MathQuill field's content
        if (mathFieldRef.current) {
            const MQ = window.MathQuill.getInterface(2);
            const mathField = MQ.MathField(mathFieldRef.current);
            mathField.latex(''); // Reset the MathQuill input to empty
        }
    };
    
    // Existing useEffect for initializing MathQuill
    useEffect(() => {
        if (window.MathQuill && mathFieldRef.current) {
            const MQ = window.MathQuill.getInterface(2);
            const mathFieldInstance = MQ.MathField(mathFieldRef.current, {
                handlers: {
                    edit: () => {
                        setMathInput(mathFieldInstance.latex());
                    }
                }
            });
    
            const handleEnterKey = (event) => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    handleSend();
                }
            };
    
            mathFieldRef.current.addEventListener('keydown', handleEnterKey);
    
            // Clean up the event listener on component unmount
            return () => {
                // Adding null check here
                if (mathFieldRef.current) {
                    mathFieldRef.current.removeEventListener('keydown', handleEnterKey);
                }
            };
        }
    }, [mathInput]); // Including mathInput in the dependency array
    
    
    
    
    // Function to play the audio
    const playAudio = async (text) => {
        try {
          setIsAudioLoading(true); // Start loading
      
          // Fetch the converted and spoken audio from your backend
          const response = await axios.post('/api/convert-and-speak', { text }, {
            responseType: 'blob' // Expecting a blob response
          });
      
          const audioUrl = URL.createObjectURL(response.data);
          const audio = new Audio(audioUrl);
      
          audio.oncanplaythrough = () => setIsAudioLoading(false); // Stop loading when ready to play
          audio.play().catch(e => {
            console.error('Error playing audio:', e);
            setIsAudioLoading(false); // Stop loading on error
          });
        } catch (error) {
          console.error('Error setting up audio:', error);
          setIsAudioLoading(false); // Stop loading on setup error
        }
      };
      
      
      

    
    
    // Function to re-fetch assistant details from the local database
const fetchUpdatedAssistant = async () => {
    try {
        // Fetch the updated assistant data from your local database
        const localAssistantResponse = await axios.get(`http://localhost:5000/api/assistants/${apiAssistantId}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        setAssistant(localAssistantResponse.data); // Update the assistant state with the latest data
    } catch (error) {
        console.error('Error fetching updated assistant:', error);
    }
};

// Refresh Chat Function
const refreshChat = async () => {
    setMessages([]); // Clear current messages

    // Re-fetch the updated assistant details
    await fetchUpdatedAssistant();

    // Set the initial message based on the updated assistant
    if (assistant) {
        setMessages([{
            role: 'assistant',
            content: assistant.chat_instructions // Assuming 'chat_instructions' contains the initial message
        }]);
    }
};


    // Function to fetch and display the image
    const fetchAndDisplayImage = async (fileId, token) => {
        try {
            const imageUrl = `${process.env.REACT_APP_BACKEND_URL}/files/content/${fileId}`;
            const response = await axios.get(imageUrl, {
                responseType: 'blob',
                headers: { Authorization: `Bearer ${token}` }
            });
            const url = URL.createObjectURL(response.data);
            const newImageMessage = {
                role: 'assistant',
                content: `<img src="${url}" alt="Image Content" style="max-width: 100%; max-height: 300px;" />`
            };
            setMessages(prevMessages => [...prevMessages, newImageMessage]);
        } catch (error) {
            console.error('Error fetching image:', error);
            setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: '[Error fetching image]' }]);
        }
    };

    // Fetch assistant data and initialize thread
    useEffect(() => {
        const initializeChat = async () => {
            try {
                if (!assistant && apiAssistantId && token) {
                    const response = await axios.get(`http://localhost:5000/api/chat/${apiAssistantId}/${token}`);
                    setAssistant(response.data);
                }

                if (!threadId) {
                    const threadResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/threads`, {}, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    });
                    setThreadId(threadResponse.data.id);
                }
            } catch (error) {
                console.error('Error initializing chat:', error);
            }
        };

        initializeChat();
    }, [assistant, apiAssistantId, token, threadId, paramApiAssistantId]);

    
    


    // Fetch messages from the thread
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const sendMessage = async (message, isInitialMessage = false) => {
        // Send the initial prompt first if it's the first user message
        if (isInitialMessage && messages.length === 1 && messages[0].role === 'assistant') {
            const initialPromptMessage = { role: 'user', content: assistant.initial_prompt };
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/threads/${threadId}/messages`, initialPromptMessage, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
        }
    
        if (message.content.type === 'code') {
            // Handle code content
            renderCodeInChat(message.content.code, message.content.language);
        } else {
            // Handle regular text message
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/threads/${threadId}/messages`, message, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setMessages(prevMessages => [...prevMessages, message]);
        }
    
        setInputValue('');
        initiateRun();
    };
    
// I don't think we use this anymore but I'm leaving it here just in case
    const renderCodeInChat = (element, codeContent, language) => {
        if (!element || element.childNodes.length > 0) {
            // Prevent re-rendering if the element already has children
            return;
        }
    
        // Initialize the CodeMirror instance
        const readOnlyEditor = new EditorView({
            state: EditorState.create({
                doc: codeContent,
                extensions: [language, lineNumbers(), EditorView.editable.of(false)],
            }),
            parent: element
        });
    };
    
 
    
    
    
   
    const handleSend = async () => {
        let combinedContent = '';
        let hasContent = false;
    
        // Append chat input
        if (inputValue.trim() !== '') {
            combinedContent += inputValue;
            hasContent = true;
        }
    
     // Append content from CodeMirror editor
if (showCodeEditor && editor) {
    const codeContent = editor.state.doc.toString();
    if (codeContent.trim() !== '') {
        if (combinedContent !== '') combinedContent += '\n';
        combinedContent += `\`\`\`${languageLabel}\n${codeContent}\n\`\`\``;
        hasContent = true;
    }
}

        // Append content from MathQuill if it's visible
        if (showMathInput && mathInput.trim() !== '') {
            if (combinedContent !== '') combinedContent += '\n'; // Add newline if there's already content
            combinedContent += `\`${mathInput}\``; // Append MathQuill input wrapped in backticks
            hasContent = true;
        }
    
        // Check if there's any content to send
        if (!hasContent) return;
        console.log("Combined Content being sent:", combinedContent);
        // Send the combined message
        const userMessage = { role: 'user', content: combinedContent };
        await sendMessage(userMessage, false); // false indicates it's not an initial message
    
        // Clear inputs and editor
        setInputValue('');
        setMathInput('');
        if (showCodeEditor && editor) {
            editor.dispatch({
                changes: { from: 0, to: editor.state.doc.length, insert: '' } // Clear editor content
            });
        }
    
        // Reset the MathQuill field's content if visible
        if (showMathInput && mathFieldRef.current) {
            const MQ = window.MathQuill.getInterface(2);
            const mathField = MQ.MathField(mathFieldRef.current);
            mathField.latex(''); // Reset the MathQuill input to empty
        }
    };
    




    
    // Function to handle the starter button click
    const handleStarterClick = async (starterContent) => {
        if (!threadId || !starterContent) return;
        const userMessage = { role: 'user', content: starterContent };
        // Pass true to indicate this might be the first message from user
        await sendMessage(userMessage, true);
    };

    // Function to initiate the run
    const initiateRun = async () => {
        setIsTyping(true);
        const runResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/threads/${threadId}/runs`, {
            assistantId: apiAssistantId
        }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        let runId = runResponse.data.id;
        let runStatus = runResponse.data.status;
        let processedStepIds = new Set();

        while (runStatus === 'queued' || runStatus === 'in_progress') {
            await new Promise(resolve => setTimeout(resolve, 1000));

            const updatedRunResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/threads/${threadId}/runs/${runId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            runStatus = updatedRunResponse.data.status;

            const runStepsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/threads/${threadId}/runs/${runId}/steps`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });

            for (const step of runStepsResponse.data.data) {
                if (processedStepIds.has(step.id) || step.status !== 'completed') continue;

                console.log(`Processing step:`, step);
                if (step.type === 'message_creation' && step.step_details.message_creation) {
                    const messageId = step.step_details.message_creation.message_id;
                    const messageResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/threads/${threadId}/messages/${messageId}`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    });

                    let imagePromise = null;
                    for (const c of messageResponse.data.content) {
                        if (c.type === 'image_file' && c.image_file) {
                            imagePromise = fetchAndDisplayImage(c.image_file.file_id, token);
                        }
                    }

                    if (imagePromise) await imagePromise;

                    for (const c of messageResponse.data.content) {
                        if (c.type === 'text' && c.text) {
                            setMessages(prev => [...prev, { role: 'assistant', content: processMessageContent(c.text.value), id: messageId }]);
                        }
                    }
                }

                processedStepIds.add(step.id);
            }
        }
        setIsTyping(false);
    };

    
    const processMessageContent = (messageContent) => {
        return messageContent.split(/(```.*?```)/s).map(segment => {
            // Check if the segment is a code block
            if (segment.startsWith("```") && segment.endsWith("```")) {
                // Return code blocks as-is
                return segment;
            } else {
                // Replace newlines with <br /> for regular text
                return segment.replace(/\n/g, '<br />');
            }
        }).join(''); // Join all segments back together
    };
    
    const convertQuillSyntaxToCodeBlock = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const preElements = doc.querySelectorAll('pre.ql-syntax');
      
        preElements.forEach(pre => {
          // Extract the code and the language from the content
          let [languageLine, ...codeLines] = pre.textContent.split('\n');
          let language = '';
          // Check if the first line is a language identifier
          if (languageLine.match(/^(javascript|python|html|css|php)$/i)) {
            language = languageLine.trim();
            codeLines = codeLines.join('\n'); // Rejoin the remaining lines
          } else {
            // If no language identifier, treat the entire text as code
            codeLines = pre.textContent;
          }
      
          // Create a Markdown-like code block with the language
          const markdownCode = `\`\`\`${language}\n${codeLines}\n\`\`\``;
          pre.outerHTML = markdownCode;
        });
      
        return doc.body.innerHTML;
      };
      
    
      useEffect(() => {
        if (assistant) {
            // Convert Quill syntax to Markdown-like code blocks
            const convertedContent = convertQuillSyntaxToCodeBlock(assistant.chat_instructions);
    
            // Process the converted content to handle code blocks
            const initialMessageContent = processMessageContent(convertedContent);
    
            setMessages([{
                role: 'assistant',
                content: initialMessageContent
            }]);
        }
    }, [assistant]);
    
    



  

    // Function to format message content with LaTeX
    const formatMessageWithLatex = (content) => {
        if (!content) {
            return ''; // Return an empty string if content is null or undefined
        }
    
        // Existing formatting code
        let formattedContent = content.replace(/`([^`]+)`/g, (_, latex) => `\\(${latex}\\)`);
        formattedContent = formattedContent.replace(/\$(.*?)\$/g, (_, latex) => `\\(${latex}\\)`);
        formattedContent = formattedContent.replace(/\\\[([^\\]+)\\\]/g, (_, latex) => `\\[${latex}\\]`);
        formattedContent = formattedContent.replace(/\\{([^\\]+)\\}/g, (_, latex) => `\\{${latex}\\}`);
    
        // Sanitize the final HTML content
       // Sanitize the final HTML content
const sanitizedContent = DOMPurify.sanitize(formattedContent, {
    ADD_TAGS: ['iframe'], // Allow iframe tags
    ADD_ATTR: ['frameborder', 'allowfullscreen', 'src', 'class'] // Allow specific attributes for iframe
  });
  return sanitizedContent;
  
    };
    

// Existing useEffect for triggering MathJax rendering
useEffect(() => {
    if (window.MathJax) {
        window.MathJax.typesetPromise();
    }
}, [messages, assistant]); 

// New useEffect for triggering MathJax rendering for MathQuill input
useEffect(() => {
    if (window.MathJax && mathToggleRef.current) {
      console.log("MathJax rendering for button");
      window.MathJax.typesetPromise([mathToggleRef.current]);
    } else {
      console.log("MathJax or mathToggleRef not available");
    }
  }, [showMathInput]);


  // Existing useEffect for CodeMirror editor
  useEffect(() => {
    // If an editor instance already exists, destroy it before creating a new one
    if (editor) {
      editor.destroy();
    }

    const newEditor = new EditorView({
      state: EditorState.create({
        doc: '//your code here', // Initial content of the editor
        extensions: [
          oneDark,
          languageMode, // Use the selected language mode
          lineNumbers(),
          keymap.of(defaultKeymap)
        ],
      }),
      parent: editorDiv.current,
    });

    setEditor(newEditor);

    // Cleanup function to destroy the editor instance when the component unmounts
    return () => {
      if (newEditor) {
        newEditor.destroy();
      }
    };
  }, [languageMode]); // Re-run the effect when languageMode changes
  
   



  const handleLanguageChange = (event) => {
    const mode = event.target.value;
    setLanguageLabel(mode); // Set the language label
    switch (mode) {
        case 'html':
            setLanguageMode(html);
            break;
        case 'css':
            setLanguageMode(css);
            break;
        case 'python':
            setLanguageMode(python);
            break;
        case 'php':
            setLanguageMode(php);
            break;
        case 'javascript':
        default:
            setLanguageMode(javascript);
            break;
    }
};



 // Function to dynamically adjust the height of the textarea
const handleTextareaChange = (e) => {
    const maxHeight = 200; // Maximum height in pixels

    // Reset the height to shrink to content size
    e.target.style.height = 'auto';

    // Set the height to the scroll height or the maximum height, whichever is smaller
    e.target.style.height = `${Math.min(e.target.scrollHeight, maxHeight)}px`;

    // Update the inputValue state
    setInputValue(e.target.value);
};


return (
    <div className={`chat-wrapper ${isInIframe ? 'iframe-scaling' : ''}`}>
        {assistant && (
            <div className={`assistant-info ${isInIframe ? 'd-none' : ''}`}> {/* Hide header in iframe */}
                <h2 className="assistant-name">{assistant.assistant_name}</h2>
               
            </div>
        )}

        {assistant && assistant.picture && (
            <div className="persona-image-container">
                <img
                    src={assistant.picture}
                    alt={`${assistant.assistant_name}'s avatar`}
                    className="persona-image"
                />
            </div>
        )}

<div className="chat-container" ref={chatContainerRef}>
<>
  {messages.map((message, index) => {
    const segments = message.content.split(/(```.*?```)/s);
    const isExpanded = expandedMessages.has(index);

    return (
        <div key={index} className={`chat-message ${message.role}`} 
        style={message.role === 'user' ? { backgroundColor: color } : { position: 'relative' }}>


        {/* Message Content */}
        {segments.map((segment, segIndex) => {
          if (segment.startsWith("```") && segment.endsWith("```")) {
            const [language, ...codeLines] = segment.substring(3, segment.length - 3).split('\n');
            const code = codeLines.join('\n').trim();
            return <CodeDisplay key={segIndex} code={code} language={language.trim()} />;
          } else {
            return (
              <React.Fragment key={segIndex}>
                <span style={{ fontWeight: 'normal' }} 
                  dangerouslySetInnerHTML={{ 
                    __html: formatMessageWithLatex(processMessageContent(segment))
                  }} 
                />
              </React.Fragment>
            );
          }
        })}

     {/* Expand/Collapse Icon at the Bottom Right */}
{message.role === 'assistant' && (
  <button 
    onClick={() => toggleExpand(index)} 
    className="expand-collapse-btn" 
    style={{
      position: 'absolute', 
      bottom: 10, 
      right: 8, 
      borderRadius: '30%',  // keeping it rounded
      padding: '4px 8px',  // reduced padding for smaller size
      fontSize: '0.7rem',   // slightly smaller font size
      backgroundColor: 'white',  // assuming a white background
      border: '1px solid #ccc',  // light border
      cursor: 'pointer'     // pointer cursor
    }}
  >
    <i className={isExpanded ? "fas fa-compress-alt" : "fas fa-expand-alt"}></i>
  </button>
)}



    {/* Play button for assistant messages */}
{message.role === 'assistant' && (
  <>
    <button onClick={() => playAudio(message.content)} style={{ fontSize: '0.8rem', borderRadius: '8px' }}>
      <i className="fa fa-play" aria-hidden="true" style={{ marginRight: '5px', fontSize: '0.8rem' }}></i>
      Audio
    </button>
    {isAudioLoading && <span>Loading audio...<br/>This voice is AI generated</span>}
  </>
)}



      </div>
    );
  })}
  
  {isTyping && (
    <div className="chat-message assistant">
      <span style={{ fontWeight: 'bold' }}>{assistant?.assistant_name || 'Assistant'} is typing
        <div className="typing-dots">
          <span></span><span></span><span></span>
        </div>
      </span>
    </div>
  )}
</>

        </div>

       {/* Render PopOut for any expanded message */}
{[...expandedMessages].map(expandedIndex => (
  <PopOut
    key={expandedIndex}
    message={messages[expandedIndex]}
    onClose={() => toggleExpand(expandedIndex)}
  />
))}


{/* CodeMirror editor container with toggleable visibility */}
<div 
    className={showCodeEditor ? "codemirror-container" : "hidden"} 
    style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', // Center child elements horizontally
        marginBottom: '12px',
        width: '90%', // Set the width to 90% for the container
        marginLeft: 'auto', // Center the container
        marginRight: 'auto'
    }}
>
    <select 
        onChange={handleLanguageChange} 
        style={{ 
            marginBottom: '10px', 
            width: 'auto',
            maxWidth: '200px',
            padding: '4px 4px',
            borderRadius: '4px',
            border: '1px solid #ced4da',
            backgroundColor: '#fff',
            boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            outline: 'none',
            fontSize: '0.9rem',
            color: '#495057',
            appearance: 'none', 
            textAlign: 'center', 
            textAlignLast: 'center',
        }}
    >
        <option value="html">HTML</option>
        <option value="javascript">JavaScript</option>
        <option value="css">CSS</option>
        <option value="python">Python</option>
        <option value="php">php</option>
    </select>
    <div ref={editorDiv} style={{ height: '100%', width: '100%' }}></div>
</div>



 {/* MathQuill container with toggleable visibility */}
 <div className={showMathInput ? "mathquill-container" : "hidden"} style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '12px' }}>
    <span 
        ref={mathFieldRef} 
        style={{ display: 'inline-block', minWidth: '200px', flexGrow: 1 }}
        className="mathquill-input"
    ></span>
    <button onClick={handleMathSubmit} className="send-button" style={{ backgroundColor: color, padding: '5px', fontSize: '10px' }}>
        <i className="bi bi-arrow-down"></i>
    </button>
</div>


{/* Chat input with toggle buttons for MathQuill and CodeMirror */}
<div style={{ display: 'flex', alignItems: 'center', gap: '5px', width: '100%' }}>
<textarea
        value={inputValue}
        onChange={handleTextareaChange}
        className="chat-input"
        placeholder="Type your message..."
        onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSend();
            }
        }}
        disabled={isTyping}
        style={{ 
            flexGrow: 1, 
            resize: 'none', 
            overflowY: 'auto',
            height: '42px' // Adjust this value based on your font size and padding
        }}
    />

   <button 
        onClick={toggleMathInput}
        className="btn btn-outline-secondary"
        style={{ 
            width: '40px', 
            height: '40px', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            marginTop: '-9px'
        }}
    >
        {showMathInput ? "hide" : <i className="fas fa-square-root-alt"></i>} {/* Shortened label for math toggle */}
    </button>
    <button 
        onClick={toggleCodeEditor}
        className="btn btn-outline-secondary"
        style={{ 
            width: '40px', 
            height: '40px', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            marginTop: '-9px'
        }}
    >
        {showCodeEditor ? "hide" : <i className="fas fa-code"></i>} {/* Shortened label for code toggle */}
    </button>
</div>



<button onClick={handleSend} className="send-button" style={{backgroundColor: color}} disabled={isTyping}>
    Send
</button>

  {/* Conversation Starters */}
<div className="mt-2">
    {assistant && assistant.starter_1 && (
        <button 
            className="btn btn-outline-secondary mb-2 w-100" 
            onClick={() => handleStarterClick(assistant.starter_1)}
            dangerouslySetInnerHTML={{ __html: formatMessageWithLatex(assistant.starter_1) }}
        />
    )}
    {assistant && assistant.starter_2 && (
        <button 
            className="btn btn-outline-secondary mb-2 w-100" 
            onClick={() => handleStarterClick(assistant.starter_2)}
            dangerouslySetInnerHTML={{ __html: formatMessageWithLatex(assistant.starter_2) }}
        />
    )}
    {assistant && assistant.starter_3 && (
        <button 
            className="btn btn-outline-secondary mb-2 w-100" 
            onClick={() => handleStarterClick(assistant.starter_3)}
            dangerouslySetInnerHTML={{ __html: formatMessageWithLatex(assistant.starter_3) }}
        />
    )}
    {assistant && assistant.starter_4 && (
        <button 
            className="btn btn-outline-secondary mb-2 w-100" 
            onClick={() => handleStarterClick(assistant.starter_4)}
            dangerouslySetInnerHTML={{ __html: formatMessageWithLatex(assistant.starter_4) }}
        />
    )}
    {assistant && assistant.starter_5 && (
        <button 
            className="btn btn-outline-secondary mb-2 w-100" 
            onClick={() => handleStarterClick(assistant.starter_5)}
            dangerouslySetInnerHTML={{ __html: formatMessageWithLatex(assistant.starter_5) }}
        />
    )}
</div>
     {/* Refresh Button */}
     <button className="btn btn-outline-secondary mt-2" onClick={refreshChat}>
                Refresh Chat
            </button>
   
    </div>
);


    
};

export default ChatComponent;
