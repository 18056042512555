import React, { useState } from 'react';
import axios from 'axios';
import './Dashboard.css';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill'; 
import ImageResize from 'quill-image-resize';

Quill.register('modules/imageResize', ImageResize);

function CreatePersona() {
  const [assistantName, setAssistantName] = useState('');
  const [description, setDescription] = useState('');
  const [model, setModel] = useState('');
  const [personality, setPersonality] = useState('');
  const [initialPrompt, setInitialPrompt] = useState('');
  const [pictureUrl, setPictureUrl] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [chatInstructions, setChatInstructions] = useState('');
  const [descriptionLocal, setDescriptionLocal] = useState('');



  // States for showing explanations
  const [showNameExplanation, setShowNameExplanation] = useState(false);
  const [showDescriptionExplanation, setShowDescriptionExplanation] = useState(false);
  const [showPersonalityExplanation, setShowPersonalityExplanation] = useState(false);
  const [showInitialPromptExplanation, setShowInitialPromptExplanation] = useState(false);
  const [showImageUrlExplanation, setShowImageUrlExplanation] = useState(false);
  const [showStartersExplanation, setShowStartersExplanation] = useState(false);
  const [showChatInstructionsExplanation, setShowChatInstructionsExplanation] = useState(false);
  const [showDescriptionLocalExplanation, setShowDescriptionLocalExplanation] = useState(false);
  const [showModelExplanation, setShowModelExplanation] = useState(false);




  // States for conversation starters
  const [starter1, setStarter1] = useState('');
  const [starter2, setStarter2] = useState('');
  const [starter3, setStarter3] = useState('');
  const [starter4, setStarter4] = useState('');
  const [starter5, setStarter5] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    try {
      const response = await axios.post(`${backendUrl}/assistants`, {
        assistantName,
        description,
        model,
        personality,
        chatInstructions,
        descriptionLocal,
        initialPrompt,
        pictureUrl,
        starter_1: starter1,
        starter_2: starter2,
        starter_3: starter3,
        starter_4: starter4,
        starter_5: starter5
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {
        window.location.href = '/dashboard';
      }
    } catch (error) {
      console.error('Error creating assistant:', error.response ? error.response.data : error.message);
      alert('Failed to create assistant. Please try again.');
    }
  };




  /*const handleImprovePersona = async () => {
    setIsProcessing(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/improve-persona`, {
        systemMessage: personality
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      setPersonality(response.data.improvedMessage);
    } catch (error) {
      console.error('Error improving persona:', error.response ? error.response.data : error.message);
      alert('Failed to improve persona. Please try again.');
    }
    setIsProcessing(false);
  };*/

  // Function to improve conversation starter
const handleImproveStarter = async (starterIndex) => {
  setIsProcessing(true);
  try {
    const starter = [starter1, starter2, starter3, starter4, starter5][starterIndex];
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/improve-starter`, {
      starterText: starter
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    const improvedStarter = response.data.improvedStarter;
    switch(starterIndex) {
      case 0: setStarter1(improvedStarter); break;
      case 1: setStarter2(improvedStarter); break;
      case 2: setStarter3(improvedStarter); break;
      case 3: setStarter4(improvedStarter); break;
      case 4: setStarter5(improvedStarter); break;
      default: break;
    }
  } catch (error) {
    console.error('Error improving starter:', error.response ? error.response.data : error.message);
    alert('Failed to improve starter. Please try again.');
  }
  setIsProcessing(false);
};


const handleGenerateDescription = async () => {
  setIsProcessing(true);
  try {
    const payload = {
      assistantName: assistantName, // State variable for assistant's name
      welcomeResources: chatInstructions, // State variable for chat instructions, used as welcome resources
      model: model, // State variable for model
      personalityBackground: personality, // State variable for assistant's personality and background
      instructionsToAssistant: initialPrompt // State variable for initial prompt (instructions to assistant)
    };

    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/generate-description`, payload, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    });

    setDescriptionLocal(response.data.generatedDescription);
  } catch (error) {
    console.error('Error generating description:', error);
    alert('Failed to generate description. Please try again.');
  }
  setIsProcessing(false);
};





  const handleUrlChange = (e) => {
    const url = e.target.value;
    setPictureUrl(url);
    setIsUrlValid(isValidUrl(url));
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <div className="container mt-5 dashboard-container">
      <div className="dashboard-header">
        <h2>Create Your AI Assistant</h2>
        <p>Complete the information below to create your custom AI teacher assistant.</p>
      </div>
      <form onSubmit={handleSubmit}>
        {/* Assistant Name */}
        <div className="form-group mb-4">
        <label><h5>Assistant Name</h5></label>

          {/*
          <button 
            type="button" 
            className="btn btn-sm ml-2" 
            style={{ background: 'transparent' }}
            onClick={() => setShowNameExplanation(!showNameExplanation)}
          >
            <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
          </button>
          
          {showNameExplanation && <p className="description-info-text">This is the name of your AI assistant.</p>}
          */}

          <input
            type="text"
            className="form-control placeholder-style"
            placeholder="Enter a name for your AI assistant..."
            value={assistantName}
            onChange={(e) => setAssistantName(e.target.value)}
            required
            disabled={isProcessing}
          />
        </div>
        
        {/* Description */}
        {/*<div className="form-group mb-4">
          <label className="font-weight-bold">Description of Assistant</label>
          <button 
            type="button" 
            className="btn btn-sm ml-2" 
            style={{ background: 'transparent' }}
            onClick={() => setShowDescriptionExplanation(!showDescriptionExplanation)}
          >
            <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
          </button>
          {showDescriptionExplanation && <p className="info-text">Provide a brief description of your AI assistant.</p>}
          <textarea
            className="form-control"
            placeholder="Describe your assistant's purpose..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
  </div>*/}
  {/* Assistant's Welcome & Resources */}
  <div className="form-group mb-4">
  <label><h5>Assistant's Welcome & Resources</h5></label>
  <button 
    type="button" 
    className="btn btn-sm ml-2" 
    style={{ background: 'transparent' }}
    onClick={() => setShowChatInstructionsExplanation(!showChatInstructionsExplanation)}
  >
    <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
  </button>

  {showChatInstructionsExplanation && (
  <div className="description-info-text">
    <p>This section allows you to craft an initial message that students will see when they start interacting with the assistant. Although it appears as part of the conversation, the AI does not recognize or "know" this content as part of its conversational context. If you want the AI to be aware of certain information or context, integrate it into the 'Assistant's Personality & Background' and/or 'Instructions to Assistant' sections.</p>
    <p>Consider the following when crafting this message:</p>
    <ul>
      <li><strong>Set the Tone:</strong> Your message should welcome students and set a friendly and inviting tone for the interaction.</li>
      <li><strong>Include Resources:</strong> Use the rich text editor to add educational content such as explanations, tips, or external links to resources. This can be articles, videos, or other learning aids relevant to the subject matter.</li>
      <li><strong>Visibility:</strong> Remember that students can expand the chat to view the content more clearly, so feel free to include detailed information or resources.</li>
      <li><strong>Encourage Interaction:</strong> Your message can include prompts or questions to encourage students to start engaging with the assistant.</li>
    </ul>
    <p>This initial message is a great opportunity to provide context, resources, and an engaging start to the AI-assisted learning experience.</p>
  </div>
)}

<ReactQuill
  theme="snow"
  value={chatInstructions}
  onChange={setChatInstructions}
  placeholder="Hello, I'm your sarcastic AI assistant. I'm here to help you with factoring!"
  style={{ height: '300px', paddingBottom: '40px' }}
  readOnly={isProcessing} // Disables editing when isProcessing is true
  modules={{
    imageResize: {},
    toolbar: [
      [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
      ['bold', 'italic', 'underline', 'strike'],       // toggled buttons
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      ['clean'],
      ['link', 'image', 'video'],                      // link and image, video
      ['code-block']
    ]
  }}
/>

</div>


       {/* Model Dropdown */}
<div className="form-group mb-4">
  <label><h5>Model</h5></label>
  <button 
    type="button" 
    className="btn btn-sm ml-2" 
    style={{ background: 'transparent' }}
    onClick={() => setShowModelExplanation(!showModelExplanation)}
  >
    <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
  </button>
  {showModelExplanation && (
    <div className="description-info-text">
      <p>Select the AI model for your assistant. Each model has different capabilities:</p>
      <ul>
        <li><strong>gpt-4-1106-preview:</strong> Advanced model offering the latest features and capabilities.</li>
        <li><strong>gpt-4:</strong> Standard GPT-4 model, balancing performance and resource usage.</li>
        <li><strong>gpt-3.5-turbo:</strong> Efficient model for faster responses, suitable for most general purposes.</li>
      </ul>
      <p>Choose the model that best fits your assistant's needs and the complexity of tasks it will handle.</p>
    </div>
  )}
  <select 
    className="form-control" 
    value={model} 
    onChange={(e) => setModel(e.target.value)}
    disabled={isProcessing}
  >
    <option value="">-- Select Model --</option>
    <option value="gpt-4-1106-preview">gpt-4-1106-preview</option>
    <option value="gpt-4">gpt-4</option>
    <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
  </select>
</div>


        {/* Personality */}
        <div className="form-group mb-4">
          <label><h5>Assistant's Personality & Background</h5></label>
          <button 
            type="button" 
            className="btn btn-sm ml-2" 
            style={{ background: 'transparent' }}
            onClick={() => setShowPersonalityExplanation(!showPersonalityExplanation)}
          >
            <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
          </button>

          {showPersonalityExplanation && (
  <div className="description-info-text">
    <p>This is where you define who your AI assistant is, including its personality traits and background. This information shapes how the assistant interacts with students, its style of communication, and its approach to teaching and assisting.</p>
    <p>Consider including:</p>
    <ul>
      <li><strong>Personality Traits:</strong> Describe the assistant's character - whether it's friendly, serious, humorous, or any other personality that aligns with your educational goals.</li>
      <li><strong>Background Information:</strong> Add details about the assistant's expertise, like a particular subject area or teaching style. This helps in creating a more engaging and realistic interaction with students.</li>
      <li><strong>Question Examples and Solutions:</strong> Incorporating examples of questions and their solutions can enrich the assistant's reference material, allowing it to provide more accurate and helpful responses.</li>
    </ul>
    <p>Remember:</p>
    <ul>
      <li><strong>Reference Material:</strong> The content here serves as a reference for the assistant. It can draw upon this information to assist students more effectively.</li>
      <li><strong>Non-Billable Text:</strong> Adding text in this section does not consume your assistant's credits. It's a cost-effective way to enhance your assistant's capabilities.</li>
    </ul>
    <p>By carefully crafting this section, you can significantly influence the effectiveness and personality of your AI assistant, making it a more valuable educational tool.</p>
  </div>
)}

          <textarea
            className="form-control placeholder-style"
            placeholder="You are a sarcastic AI assistant who is here to help students with [subject/topic]..."
            value={personality}
            onChange={(e) => setPersonality(e.target.value)}
            required
            rows={6}
            disabled={isProcessing}
          />
        {/*  <button 
            type="button" 
            className="btn btn-outline-secondary mt-2" 
            onClick={handleImprovePersona} 
            disabled={isProcessing}
          >
            Improve My Assistant
          </button> */}
        </div>

        {/* Knowledge File 
        <div className="form-group mb-4">
          <label className="font-weight-bold">Assistant Knowledge File (Optional)</label>
          <input
            type="file"
            className="form-control"
            onChange={handleFileChange}
          />
        </div> */}

      

        {/* Instructions to Assistant */}
        <div className="form-group mb-4">
          <label><h5>Instructions to Assistant</h5></label>
          <button 
            type="button" 
            className="btn btn-sm ml-2" 
            style={{ background: 'transparent' }}
            onClick={() => setShowInitialPromptExplanation(!showInitialPromptExplanation)}
          >
            <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
          </button>
          {showInitialPromptExplanation && (
  <div className="description-info-text">
    <p>Use this section to provide specific directions to the assistant for its interactions with your students.</p>
    
    <p><strong>A recommended way to start your instructions:</strong></p>
    <blockquote>
      "As a teaching assistant, you will now engage with my students to help them understand [subject/topic]."
    </blockquote>

    <p>This statement informs the AI of its role and purpose but will not be displayed in the student-facing chat interface.</p>

    <p><strong>Key aspects to include in your instructions:</strong></p>
    <ul>
      <li><strong>Role Definition:</strong> Clearly articulate the assistant's role in relation to the subject or activity.</li>
      <li><strong>Objectives for Interaction:</strong> State the primary goals for student interactions, like problem-solving, explaining concepts, or encouraging exploration.</li>
      <li><strong>Guidelines for Behavior:</strong> Specify the expected style and tone of the assistant's responses.</li>
    </ul>
    
    <p><strong>Credit Usage:</strong></p>
    <ul>
      <li>The instructions are understood by the AI and influence its responses but remain invisible in the chat to students.</li>
      <li>Given that this content is processed in each student's interaction, it contributes to credit usage, hence brevity is advised.</li>
      <li>Detailed examples and extensive background information can be more credit-efficiently included in the 'Assistant's Personality & Background' section.</li>
    </ul>
    
    <p>Strategically structuring this section helps you direct the assistant effectively while managing credit use.</p>
  </div>
)}
      <textarea
  className="form-control placeholder-style"
  placeholder="As a teaching assistant, you will now engage with my students to help them understand [subject/topic]."
  value={initialPrompt}
  onChange={(e) => setInitialPrompt(e.target.value)}
  required
  rows={6}
  disabled={isProcessing}
/>

        </div>

{/* Conversation Starters Section */}
<div className="form-group mb-4">
  <label><h5>Conversation Starters</h5></label>
  <button 
    type="button" 
    className="btn btn-sm ml-2" 
    style={{ background: 'transparent' }}
    onClick={() => setShowStartersExplanation(!showStartersExplanation)}
  >
    <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
  </button>
  {showStartersExplanation && (
  <div className="description-info-text">
    <p>This section is for creating quick-access prompts that students can use to initiate specific topics or questions in their chat with the assistant. These starters are designed to guide student interactions, making it easier for them to engage with the AI on relevant topics.</p>

    <p>Consider these tips for creating effective conversation starters:</p>
    <ul>
      <li><strong>Common Questions:</strong> Include prompts for frequently asked questions or common topics in your subject area.</li>
      <li><strong>Guided Learning:</strong> Use starters to steer students towards particular learning objectives or key concepts.</li>
      <li><strong>Variety:</strong> Offer a range of starters covering different aspects of the subject to cater to diverse student needs and interests.</li>
    </ul>
    
    <p><strong>Pro Tip:</strong> Leverage conversation starters for tailored AI responses. By setting a starter to a specific question or phrase, and then referencing it in the 'Assistant's Personality & Background' or 'Instructions to Assistant,' you can program the AI to take a particular action or provide a specific type of response when a student selects that starter. This allows for more controlled and predictable interactions based on the selected prompt.</p>
    
    <p>Well-crafted conversation starters not only make the chat more interactive but also enhance the educational value of the AI assistant by focusing discussions on key learning areas.</p>
    <p>Note: For math to render properly in this section it needs to be writted in LaTeX form and enclosed in backticks. You can click the 'Format Math' button to have AI convert your math into the correct format</p>
  </div>
)}
  
  <div className="d-flex align-items-start mb-2">
    <textarea className="form-control me-2 placeholder-style" placeholder="How do I factor `x^2-2x-15`" value={starter1} onChange={(e) => setStarter1(e.target.value)} rows={2} disabled={isProcessing}/>
    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => handleImproveStarter(0)} disabled={isProcessing}>
    Format Math/Code
    </button>
  </div>

  <div className="d-flex align-items-start mb-2">
    <textarea className="form-control me-2 placeholder-style" placeholder="Why can't I factor out an x?" value={starter2} onChange={(e) => setStarter2(e.target.value)} rows={2} disabled={isProcessing} />
    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => handleImproveStarter(1)} disabled={isProcessing}>
      Format Math/Code
    </button>
  </div>

  <div className="d-flex align-items-start mb-2">
    <textarea className="form-control me-2 placeholder-style" placeholder="Help me figure out numbers that multiply to -18 and add to 3" value={starter3} onChange={(e) => setStarter3(e.target.value)} rows={2} disabled={isProcessing}/>
    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => handleImproveStarter(2)} disabled={isProcessing}>
    Format Math/Code
    </button>
  </div>

  <div className="d-flex align-items-start mb-2">
    <textarea className="form-control me-2 placeholder-style" placeholder="Conversation Starter 4" value={starter4} onChange={(e) => setStarter4(e.target.value)} rows={2} disabled={isProcessing}/>
    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => handleImproveStarter(3)} disabled={isProcessing}>
    Format Math/Code
    </button>
  </div>

  <div className="d-flex align-items-start mb-2">
    <textarea className="form-control me-2 placeholder-style" placeholder="Conversation Starter 5" value={starter5} onChange={(e) => setStarter5(e.target.value)} rows={2} disabled={isProcessing}/>
    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => handleImproveStarter(4)} disabled={isProcessing}>
    Format Math/Code
    </button>
  </div>
</div>


        {/* Image URL */}
        <div className="form-group mb-4">
          <label><h5>Image URL (Optional)</h5></label>
          <button 
            type="button" 
            className="btn btn-sm ml-2" 
            style={{ background: 'transparent' }}
            onClick={() => setShowImageUrlExplanation(!showImageUrlExplanation)}
          >
            <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
          </button>
          {showImageUrlExplanation && (
  <div className="description-info-text">
    <p>This section allows you to add a visual representation for your AI assistant. The image you choose will appear at the top of the chat interface, providing a personalized touch and making the chat experience more engaging for students.</p>
    
    <p>To add an image:</p>
    <ul>
      <li><strong>Find an Image:</strong> Choose an image that best represents the character or theme of your AI assistant. This could be a relevant photograph, illustration, or any visual that complements the assistant's personality.</li>
      <li><strong>Get the Image URL:</strong> Right-click on the image and select 'Copy image link.' If the link doesn't work directly, try clicking on the image to view it in full size before copying the link.</li>
      <li><strong>Test the Link:</strong> Ensure the image URL works by pasting it into your browser's address bar and checking if the image loads correctly.</li>
      <li><strong>Respect Copyright:</strong> Use only images that are free for use or for which you have the necessary permissions.</li>
    </ul>

    <p>Adding an image makes the assistant more visually appealing and relatable, contributing to a richer interactive experience for students.</p>
  </div>
)}

          <input
            type="text"
            className="form-control placeholder-style"
            placeholder="Provide an image URL..."
            value={pictureUrl}
            onChange={handleUrlChange}
            disabled={isProcessing}
          />
          {!isUrlValid && <p className="text-danger">Invalid URL</p>}
        </div>

 {/* Description of Assistant */}
        <div className="form-group mb-4">
  <label><h5>Description of Assistant</h5></label>
  <button 
    type="button" 
    className="btn btn-sm ml-2" 
    style={{ background: 'transparent' }}
    onClick={() => setShowDescriptionLocalExplanation(!showDescriptionLocalExplanation)}
  >
    <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
  </button>

  {showDescriptionLocalExplanation && (
  <div className="description-info-text">
    <p>This description serves a dual purpose:</p>
    <ul>
      <li>
        <strong>Personal Reminder:</strong> It acts as a reminder for you, the creator, about the specific functions, characteristics, and intended use of your AI assistant. This is particularly helpful if you manage multiple assistants or need to recall the distinct features of this assistant at a later time.
      </li>
      <li>
        <strong>Information for Other Teachers (if Public):</strong> If your AI assistant is shared publicly or with other teachers, this description provides a concise overview of what the assistant does. It informs other educators about the assistant's capabilities, usage scenarios, and any unique attributes that make it useful for educational purposes.
      </li>
    </ul>
    <p>In crafting your description, consider including:</p>
    <ul>
      <li>The main objectives and teaching goals of the assistant.</li>
      <li>Specific subjects, topics, or areas of expertise the assistant covers.</li>
      <li>Unique interaction styles or pedagogical approaches used by the assistant.</li>
      <li>Any prerequisites or intended audience (e.g., grade levels, knowledge level).</li>
      <li>Brief examples or use cases to illustrate how the assistant can be utilized in an educational setting.</li>
    </ul>
    <p>Remember, a clear and detailed description will enhance the usability of your assistant for both yourself and other educators, fostering a more collaborative and informed teaching environment.</p>
  </div>
)}

<div className="d-flex align-items-start mb-2">
  <textarea
    className="form-control me-2 placeholder-style"
    placeholder="Describe how your AI assistant works..."
    value={descriptionLocal}
    onChange={(e) => setDescriptionLocal(e.target.value)}
    rows={5} // Adjust the number of rows as needed
    disabled={isProcessing}
  />
  <button 
    type="button" 
    className="btn btn-outline-secondary btn-sm custom-button-size" 
    onClick={handleGenerateDescription}
    disabled={isProcessing}
  >
    Generate Description Using AI
  </button>
</div>



</div>



<button type="submit" className="btn btn-outline-primary" disabled={isProcessing}>
  <i className="bi bi-plus-square-fill" style={{ marginRight: '8px' }}></i>
  Create Assistant
</button>

      </form>
    </div>
  );
}

export default CreatePersona;