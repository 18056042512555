import React from 'react';
import './App.css'; 
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import CreatePersona from './components/CreatePersona';
import ChatComponent from './components/ChatComponent';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LayoutWithNavbar><LandingPage /></LayoutWithNavbar>} />
          <Route path="/login" element={<LayoutWithNavbar><Login /></LayoutWithNavbar>} />
          <Route path="/register" element={<LayoutWithNavbar><Register /></LayoutWithNavbar>} />
          <Route path="/dashboard" element={<LayoutWithNavbar><Dashboard /></LayoutWithNavbar>} />
          <Route path="/create-persona" element={<LayoutWithNavbar><CreatePersona /></LayoutWithNavbar>} />
          <Route path="/chat/:apiAssistantId/:token" element={<ChatComponent />} />
        </Routes>
      </div>
    </Router>
  );
}

// Layout component with Navbar
function LayoutWithNavbar({ children }) {
  return (
    <>
      <Navbar />
      {children}
    </>
  );
}

// Navbar component
function Navbar() {
  return (
    <nav className="navbar" style={{ background: 'rgba(0, 0, 0, 0.8)' }}>
      <Link to="/">
        <i className="icon ion-ios-home-outline" style={{ fontSize: '1.5em' }}></i>
      </Link>
      {/* Additional links can be added based on the user's authentication state */}
    </nav>
  );
}

export default App;
